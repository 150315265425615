import "./project.css";
import BuiltEnvSimsConceptImg from "../../img/Simulations in the Built Environment/Agent-Based/JCH_Covid_Spread2.png";
import BuildingBGImg from "../../img/Simulations in the Built Environment/Building-stock-img.png";
import ObjectDiagram from "../../img/Simulations in the Built Environment/ModelDesign/MovieTheaterSystemObjectDiagram.png";
import ClassDiagram from "../../img/Simulations in the Built Environment/ModelDesign/MovieTheaterAuditorumSystemClass Diagram.png";
import AgentUserCaseDiagram from "../../img/Simulations in the Built Environment/ModelDesign/MovieTheaterAuditoriumAgent_UserCaseDiagram.png";
import BehaviorActivityDiagram from "../../img/Simulations in the Built Environment/ModelDesign/Movie Theater AuditorumSystemBehaviorActivityDiagram.jpg";
import CodeImg from "../../img/Simulations in the Built Environment/ModelDesign/MovieTheaterSystem.png";
import AgentBased from "../../img/Simulations in the Built Environment/Agents.png";
import MesaSEIRD from "../../img/Simulations in the Built Environment/Agent-Based/Mesa_SEIRD.mp4";
import JCHFourthFloor from "../../img/Simulations in the Built Environment/Agent-Based/JCH_Fourth_Floor.mp4";
import JCHCovidSpread from "../../img/Simulations in the Built Environment/Agent-Based/JCH_Covid_Spread2.mp4";
import AnyLogic2 from "../../img/Simulations in the Built Environment/Agent-Based/AnyLogic_Maze2.mp4";

import { useContext } from "react";
import { ThemeContext } from "../../context";

// For popup images
import ModalImage from "react-modal-image";

// Carousel imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import CardImage from "./CardImage";
import CardVideo from "./CardVideo";

export const BuiltEnvironmentSimulations = () =>
{
    // Enables theme control
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1, 
        autoplay: true,
        autoplaySpeed: 3000, 
        slickPlay: true
    };

    return (
        <div className="ppg">
            <div className="ppg-grid-container">
                <div className="ppg-grid-item-row">
                    <h1 className="ppg-title">Simulations in the Built Environment</h1>
                    <p className="ppg-paragraph">Depictions of model design and agent-based simulations in manmade environments</p>
                </div>
            </div>

            <Slider {...settings}>
                <CardImage source={CodeImg} />
                <CardVideo source={AgentBased} type="video/mp4"/>
                <CardVideo source={MesaSEIRD} type="video/mp4" />
                <CardVideo source={JCHFourthFloor} type="video/mp4" />
                <CardVideo source={JCHCovidSpread} type="video/mp4" />
                <CardVideo source={AnyLogic2} type="video/mp4" />
                <CardImage source={ObjectDiagram} />
                <CardImage source={ClassDiagram} />
                <CardImage source={AgentUserCaseDiagram} />
                <CardImage source={BehaviorActivityDiagram} />
            </Slider>

            <div className="ppg-grid-container">
                <div className="ppg-grid-item-row">
                    <p className="ppg-paragraph" >
                    Focusing on the spread of infection in the built environment, the project consists of two major milestones: model design and agent-based simulation. This project was developed for a graduate Simulation Techniques course. <br/><br/> My primary responsibilities: <i>Model design, data analysis, development</i>
                    </p>
                </div>
                <div className="ppg-grid-item-row">
                    <h3 className="ppg-subtitle" >Tools</h3>
                    <div className="ppg-grid-container-four-col">
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb14">
                                <div className="ppg-tools-text">Python</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb15">
                                <div className="ppg-tools-text">Mesa Library</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb16">
                                <div className="ppg-tools-text">AnyLogic</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb1">
                                <div className="ppg-tools-text">GitHub</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="ppg-grid-item-row">
                    <h3 className="ppg-subtitle" >Links</h3>
                    <div className="ppg-grid-item-col">
                        <a target="_blank" className="pp-visit-site" href="https://sciences.ucf.edu/anthropology/datch/"><button className="ppg-button">Visit Website</button></a>
                        <a target="_blank" className="pp-visit-site" href="https://github.com/datch-ucf/datch2-docs"><button className="ppg-button">Visit GitHub</button></a>
                    </div>
                </div> */}
                <div className="ppg-grid-item-row">
                        <a href="/#work-anchor" target="_self" className="pp-back-link">Go Back</a>
                </div>
            </div>
        </div>
    ) 
};