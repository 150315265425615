// import { useContext } from "react";
// import { ThemeContext } from "./context";
import Toggle from "./components/toggle/Toggle";
import './App.css';
import PropogateLoader from "react-spinners/PropagateLoader";
import React, { useState, useEffect } from "react";

// Router components
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Home } from "./pages/Home/Home";
import { DATCH } from "./pages/Projects/DATCH2";
import { SpatialSafari } from "./pages/Projects/SpatialSafari";
import { SpatialCatbotConvo } from "./pages/Projects/SpatialCatbotConvo";
import { VRPlayspace } from "./pages/Projects/VRPlayspace2";
import { LaserScanning } from "./pages/Projects/LaserScanning2";
import { DigitalTwin } from "./pages/Projects/DigitalTwin2";
// import { SlimeMoldSimulation } from "./pages/Projects/SlimeMoldSimulation";
import { BuiltEnvironmentSimulations } from "./pages/Projects/BuiltEnvironmentSimulations2";
import { Navbar } from "./Navbar";
import { Footer } from "./Footer";

// Markdown variables
// import MarkdownViewer from './pages/MarkdownPages/MarkdownViewer';
// const markdownFilePath = require('./pages/MarkdownPages/TestPage.md');


const App = () =>
{
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  },[])

  return (
    <div className="App">
    {
        loading ?
        <div className="loader">
          <PropogateLoader color={"#FF5E5B"} loading={loading} size={30} />
        </div>
          : (
          
          <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={ <Home />} />
            {/* <Route path="/menu" element={ <Menu />} />  */}
            <Route path="/datch" element={ <DATCH />} />
            <Route path="/spatial-safari" element={ <SpatialSafari />} />
            <Route path="/spatial-catbot-convo" element={ <SpatialCatbotConvo />} />
            <Route path="/vr-playspace" element={ <VRPlayspace />} />
            <Route path="/laser-scanning" element={ <LaserScanning />} />
            <Route path="/digital-twin" element={ <DigitalTwin />} />
            {/* <Route path="/slime-mold-simulation" element={ <SlimeMoldSimulation />} />  */}
            <Route path="/built-environment-simulations" element={<BuiltEnvironmentSimulations />} />
            {/* <Route path="/test-md" element={ <MarkdownViewer markdownFilePath={markdownFilePath} /> } /> */}
            <Route path="*" element={ <h1> PAGE NOT FOUND </h1>} />
          </Routes>
          <Footer />
        </Router>
      )}
    </div>
  );
};

export default App;
