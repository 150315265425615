import "./intro.css";
// import { ReactComponent as HomeScribble } from '../../svg/HomeScribble.svg';
// import { ReactComponent as HomeDots } from '../../svg/Dots.svg';
import { ReactComponent as PageBreak } from '../../svg/PageBreak.svg';
import { useContext } from "react";
import { ThemeContext } from "../../context";
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Intro = () => {
    // Enables theme control
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    useEffect(() => {
        AOS.init({ duration: 2000 })
    }, []);

    return (
        // i means intro
        <div className="i" data-aos="fade-up">
            <div class="i-grid-container">
                <div class="i-grid-item-col"></div>
                <div class="i-grid-item-col"></div>
                <div class="i-grid-item-col"></div>
                <div class="i-grid-item-col"></div>
                <div class="i-grid-item-col">
                    <div className="i-flexbox-item">
                        <h2 className="i-intro">Hi, my name is</h2>
                    </div>
                    <div className="i-flexbox-item name-flexbox">
                        <h1 className="i-name">Jeralyn Parchman</h1>
                    </div>
                    {/* <div className="i-flexbox-item">
                        <svg className="i-home-scribble" style={{ fill: darkMode && "white" }}><HomeScribble/></svg>
                    </div> */}
                    <div className="i-flexbox-item">
                        {/* <div className="i-title-item">
                        XR Developer & Modeling and Simulation
                            <br />
                            Developer <div className="i-des-text">/ Designer</div> */}
                            {/* Developer / Designer  */}
                        {/* <div className="i-dev-text">Developer</div><div className="i-and-text"> / </div><div className="i-des-text">Designer</div> */}
                        {/* </div> */}
                        <div className="i-title-item">I develop and design AR/VR/MR applications and simulations for computer generated worlds.</div>
                    </div>
                    
                </div>
                <div class="i-grid-item-row"></div>
                <div className="i-grid-item-space"></div>
                <div class="i-grid-item-row">
                    <div className="i-flexbox-item">
                        <div className="i-home-dots">...</div>
                        {/* <svg className="i-home-dots" style={{ stroke: darkMode && "white" }}><HomeDots/></svg> */}
                    </div>
                </div>
                <div class="i-grid-item-space"></div>
            </div>

            {/* Grid for page break */}
            <div className="i-grid-container-single-row">
                <div className="i-grid-item-space"></div>
                <div className="i-grid-item-row">
                    <div className="i-flexbox-item">
                        <svg className="i-page-break"><PageBreak/></svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Intro