import "./projects-gallery.css";
import { ReactComponent as PageBreak } from '../../svg/PageBreak.svg';
import DATCHMain from "../../img/DATCH/old-datch-site-demo.jpg";
import VRPlayspaceMain from "../../img/Virtual Reality Playspace/AestheticDesign.png";
import LiDARScanningMain from "../../img/Partnership 2 Lobby Laser Scans/p2lobbyint__2.png";
import DigitalTwinMain from "../../img/Digital Twin/P2 Office - Rhino.png";
import AppearanceManifoldsMain from "../../img/Appearance Manifolds Slime Molds/slime-mold-pexels.jpg";
import AgentBasedMain from "../../img/Simulations in the Built Environment/Agent-Based/JCH_Covid_Spread2.png";
import SpatialSafariMain from "../../img/Spatial-Safari/SpatialSafariScreenshot.png"
import SpatialCatbotConvoMain from "../../img/Spatial-Catbot-Convo/CatbotConvoScreenshot.png"
import { Link } from "react-router-dom";
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ProjectsGallery = () => {

    useEffect(() => {
        AOS.init({ duration: 2000 })
    }, []);
    
    return (
        // pg means project gallery
        <div className="pg" data-aos="fade-up">
            {/* Grid for Work and other text descriptions */}
            <div className="pg-grid-container-single-row">
                <div className="pg-grid-item-col-left-align">
                    <div className="pg-flexbox-item">
                        <a id="work-anchor"><h1 className="pg-title">Work</h1></a>
                    </div>
                    <div className="pg-flexbox-item">
                        <p className="pg-desc">
                            These are some of the projects I've been working on.
                        </p>
                    </div>
                </div>
                <div className="pg-grid-item-space"></div>
            </div>

            {/* Grid for Work */}
            <div className="pg-grid-container-three-col">
                {/* DATCH Image and Title */}
                <div className="pg-grid-item-col">
                    <Link to="/datch" className="pg-proj-link" onClick={() => {window.scroll(0, 0);}}>
                        <div className="pg-flexbox-item">
                            <div className="pg-grid-item-col-proj-img">
                                <img src={DATCHMain} alt="" className="pg-proj-img"/>
                            </div>
                        </div>
                    </Link>
                    <div className="pg-flexbox-item pg-project-overlay" id="project-overlay">
                        <p className="pg-overlay-text"><b className="pg-overlay-title">DATCH</b><br/>An augmented reality app that allows archaeologists to draw, record, and document artifacts and structures</p>
                    </div>
                </div>

                {/* Spatial Safari Image and Title */}
                <div className="pg-grid-item-col">
                    <Link to="/spatial-safari" className="pg-proj-link" onClick={() => {window.scroll(0, 0);}}>
                        <div className="pg-flexbox-item">
                            <div className="pg-grid-item-col-proj-img">
                                <img src={SpatialSafariMain} alt="" className="pg-proj-img"/>
                            </div>
                        </div>
                    </Link>
                    <div className="pg-flexbox-item pg-project-overlay" id="project-overlay">
                        <p className="pg-overlay-text"><b className="pg-overlay-title">Spatial Safari</b><br/>A Spatial environment where users find and photograph hiding animals</p>
                    </div>
                </div>
                {/* Spatial Catbot Convo Image and Title */}
                <div className="pg-grid-item-col">
                    <Link to="/spatial-catbot-convo" className="pg-proj-link" onClick={() => {window.scroll(0, 0);}}>
                        <div className="pg-flexbox-item">
                            <div className="pg-grid-item-col-proj-img">
                                <img src={SpatialCatbotConvoMain} alt="" className="pg-proj-img"/>
                            </div>
                        </div>
                    </Link>
                    <div className="pg-flexbox-item pg-project-overlay" id="project-overlay">
                        <p className="pg-overlay-text"><b className="pg-overlay-title">Spatial Catbot Convo</b><br/>A Spatial environment where users talk with a robot</p>
                    </div>
                </div>

                {/* VR Playspace Image and Title */}
                <div className="pg-grid-item-col">
                    <Link to="/vr-playspace" className="pg-proj-link" onClick={() => {window.scroll(0, 0);}}>
                        <div className="pg-flexbox-item">
                            <div className="pg-grid-item-col-proj-img">
                                <img src={VRPlayspaceMain} alt="" className="pg-proj-img"/>
                            </div>
                        </div>
                    </Link>
                    <div className="pg-flexbox-item pg-project-overlay" id="project-overlay">
                        <p className="pg-overlay-text"><b className="pg-overlay-title">VR Playspace</b><br/>A VR environment that includes tools for exploration</p>
                    </div>
                </div>

                {/* LiDAR Scanning Image and Title */}
                <div className="pg-grid-item-col">
                    <Link to="/laser-scanning" className="pg-proj-link" onClick={() => {window.scroll(0, 0);}}>
                        <div className="pg-flexbox-item">
                            <div className="pg-grid-item-col-proj-img">
                                <img src={LiDARScanningMain} alt="" className="pg-proj-img"/>
                            </div>
                        </div>
                    </Link>
                    <div className="pg-flexbox-item pg-project-overlay" id="project-overlay">
                        <p className="pg-overlay-text"><b className="pg-overlay-title">Laser Scanning</b><br/>A digital recreation of a building lobby constructed using laser scanning tools</p>
                    </div>
                </div>

                {/* Digital Twin Image and Title */}
                <div className="pg-grid-item-col">
                    <Link to="/digital-twin" className="pg-proj-link" onClick={() => {window.scroll(0, 0);}}>
                        <div className="pg-flexbox-item">
                            <div className="pg-grid-item-col-proj-img">
                                <img src={DigitalTwinMain} alt="" className="pg-proj-img"/>
                            </div>
                        </div>
                    </Link>
                    <div className="pg-flexbox-item pg-project-overlay" id="project-overlay">
                        <p className="pg-overlay-text"><b className="pg-overlay-title">Digital Twin</b><br/>A digital office space model created with Rhino, Python, and Autodesk Maya</p>
                    </div>
                </div>

                {/* Agent-based Sim Image and Title */}
                <div className="pg-grid-item-col">
                    <Link to="/built-environment-simulations" className="pg-proj-link" onClick={() => {window.scroll(0, 0);}}>
                        <div className="pg-flexbox-item">
                            <div className="pg-grid-item-col-proj-img">
                                <img src={AgentBasedMain} alt="" className="pg-proj-img"/>
                            </div>
                        </div>
                    </Link>
                    <div className="pg-flexbox-item pg-project-overlay" id="project-overlay">
                        <p className="pg-overlay-text"><b className="pg-overlay-title">Simulations in the Built Environment</b><br/>Depictions of model design and agent-based simulations in manmade environments</p>
                    </div>
                </div>

            </div>

            {/* Making another grid (of only two columns) for now to help with spacing */}
            {/* <div className="pg-grid-container-two-col"> */}
                {/* Digital Twin Image and Title */}
                {/* <div className="pg-grid-item-col">
                    <Link to="/digital-twin" className="pg-proj-link" onClick={() => {window.scroll(0, 0);}}>
                        <div className="pg-flexbox-item">
                            <div className="pg-grid-item-col-proj-img">
                                <img src={DigitalTwinMain} alt="" className="pg-proj-img"/>
                            </div>
                        </div>
                    </Link>
                    <div className="pg-flexbox-item pg-project-overlay" id="project-overlay">
                        <p className="pg-overlay-text"><b className="pg-overlay-title">Digital Twin</b><br/>A digital office space model created with Rhino, Python, and AutoDesk Maya</p>
                    </div>
                </div> */}
                
                {/* Appearance Mainfolds Image and Title */}
                {/* <div className="pg-grid-item-col">
                    <Link to="/slime-mold-simulation" className="pg-proj-link" onClick={() => {window.scroll(0, 0);}}>
                        <div className="pg-flexbox-item">
                            <div className="pg-grid-item-col-proj-img">
                                <img src={AppearanceManifoldsMain} alt="" className="pg-proj-img"/>
                            </div>
                        </div>
                    </Link>
                    <div className="pg-flexbox-item pg-project-overlay" id="project-overlay">
                        <p className="pg-overlay-text"><b className="pg-overlay-title">Slime Molds Simulation</b><br/>An application that creates texture maps from a single reference image and mimics the behavior of slime molds</p>
                    </div>
                </div> */}

                {/* Agent-based Sim Image and Title */}
                {/* <div className="pg-grid-item-col">
                    <Link to="/built-environment-simulations" className="pg-proj-link" onClick={() => {window.scroll(0, 0);}}>
                        <div className="pg-flexbox-item">
                            <div className="pg-grid-item-col-proj-img">
                                <img src={AgentBasedMain} alt="" className="pg-proj-img"/>
                            </div>
                        </div>
                    </Link>
                    <div className="pg-flexbox-item pg-project-overlay" id="project-overlay">
                        <p className="pg-overlay-text"><b className="pg-overlay-title">Simulations in the Built Environment</b><br/>Depictions of model design and agent-based simulations in manmade environments</p>
                    </div>
                </div> */}
            {/* </div> */}
        </div>
    )
}

export default ProjectsGallery