// import Intro from "../components/intro/Intro";
// import About from "../components/about/About";
// import ProductList from "../components/productList/ProductList";
// import Contact from "../components/contact/Contact";
// import FlexboxTest from "../pages/../../pages/FlexboxTest";
import Intro from "./Intro";
import ProjectsGallery from "./ProjectsGallery";
import About from "./About";
import Contact from "./Contact";
import "./home.css";

export const Home = () =>
{
    return (
        <div>
            <Intro />
            <ProjectsGallery />
            <About />
            <Contact />
            
            {/* If you wanted to, you could enclose all of these functions inside <ThemeProvider></ThemeProvider> brackets and the theme would affect only the elements enclosed. 
            You can also just call the theme provider in the index.js, which we're going to do. */}

        </div>
    )
};
