import "./about.css";
import Avatar from "../../img/HeadsetPortraitWOutlineNatural.png";
import { ReactComponent as PageBreak } from '../../svg/PageBreak.svg';
import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';


const About = () =>
{   
    useEffect(() => {
        AOS.init({ duration: 2000 })
    }, []);

    return (
        // a means about
        <div className="a" data-aos="fade-up">
            {/* First About Me header section */}
            <div class="a-grid-container-single-row">
                <div className="a-grid-item-space"></div>
                <div className="a-grid-item-col-left-align">
                    <div className="a-flexbox-item">
                        <a id="about-anchor"><h1 className="a-title">About Me</h1></a>
                    </div>
                    <div className="a-flexbox-item">
                        <p className="a-sub">Learn about my background here.</p>
                    </div>
                </div>
            </div>

            {/* Profile image and description */}
            <div className="a-grid-container">
                {/* <div className="a-grid-item-col">
                    <div className="a-flexbox-item">
                        <img src={Avatar} alt="" className="a-avatar" />
                    </div>
                </div> */}
                <div className="a-grid-item-col-left-align">
                    <div className="a-flexbox-item">
                        <p className="a-desc">
                        I am a software developer specializing in augmented reality, virtual reality, and mixed reality applications and exploring the possibilities of 3D computer graphics with simulation. I currently hold a masters degree in Modeling and Simulation from the University of Central Florida, as well as a bachelors degree in Information Technology and Informatics from Rutgers University, where I graduated magna cum laude. Throughout my academic and professional career, I have refined my skills in XR and software development. I am eager to find opportunities to combine creativity with simulation to produce innovative applications for the future. Aside from programming, my hobbies include playing video games, reading art and design books, learning new languages, and testing personal knowledge-management apps.
                        </p>
                    </div>
                    {/* <div className="a-flexbox-item">
                        <a href={Resume} target='_blank' rel='noopener noreferrer' className="resume-link">Download My Resume</a>
                    </div> */}
                </div>
            </div>
            <div className="a-grid-container-single-row">
                <div className="a-grid-item-space"></div>
                {/* Software Skills header */}
                <div className="a-grid-item-col-left-align">
                    <div className="a-flexbox-item">
                        <p className="a-sub tech-software">My technical and software skills</p>
                    </div>
                </div>    

                {/* Software Skills grid */}
                <div className="a-grid-container-five-col">
                    <div className="a-grid-item-col-left-align">
                        <div className="a-flexbox-item a-sketchy-box-text-holder sb1">
                            <div className="a-sketchy-box-text">
                                Unity
                            </div>
                        </div>
                    </div>
                    <div className="a-grid-item-col-left-align">
                        <div className="a-flexbox-item a-sketchy-box-text-holder sb2">
                            <div className="a-sketchy-box-text">
                                C#
                            </div>
                        </div>
                    </div>
                    <div className="a-grid-item-col-left-align">
                        <div className="a-flexbox-item a-sketchy-box-text-holder sb3">
                            <div className="a-sketchy-box-text">
                                Python
                            </div>
                        </div>
                    </div>
                    <div className="a-grid-item-col-left-align">
                        <div className="a-flexbox-item a-sketchy-box-text-holder sb4">
                            <div className="a-sketchy-box-text">
                                Java
                            </div>
                        </div>
                    </div>
                    <div className="a-grid-item-col-left-align">
                        <div className="a-flexbox-item a-sketchy-box-text-holder sb5">
                            <div className="a-sketchy-box-text">
                                Blender
                            </div>
                        </div>
                    </div>
                    <div className="a-grid-item-col-left-align">
                        <div className="a-flexbox-item a-sketchy-box-text-holder sb6">
                            <div className="a-sketchy-box-text">
                                Data Analysis
                            </div>
                        </div>
                    </div>
                    <div className="a-grid-item-col-left-align">
                        <div className="a-flexbox-item a-sketchy-box-text-holder sb7">
                            <div className="a-sketchy-box-text">
                                Git
                            </div>
                        </div>
                    </div>
                    <div className="a-grid-item-col-left-align">
                        <div className="a-flexbox-item a-sketchy-box-text-holder sb8">
                            <div className="a-sketchy-box-text">
                                Autodesk Maya
                            </div>
                        </div>
                    </div>
                    <div className="a-grid-item-col-left-align">
                        <div className="a-flexbox-item a-sketchy-box-text-holder sb9">
                            <div className="a-sketchy-box-text">
                                LiDAR
                            </div>
                        </div>
                    </div>
                    
                    <div className="a-grid-item-col-left-align">
                        <div className="a-flexbox-item a-sketchy-box-text-holder sb10">
                            <div className="a-sketchy-box-text">
                                Lua
                            </div>
                        </div>
                    </div>
                    <div className="a-grid-item-col-left-align">
                        <div className="a-flexbox-item a-sketchy-box-text-holder sb11">
                            <div className="a-sketchy-box-text">
                                Figma
                            </div>
                        </div>
                    </div>
                    <div className="a-grid-item-col-left-align">
                        <div className="a-flexbox-item a-sketchy-box-text-holder sb12">
                            <div className="a-sketchy-box-text">
                                UX Design
                            </div>
                        </div>
                    </div>
                </div>

                {/* Page Break Section (No longer a separate grid)*/}
                <div className="a-grid-item-space"></div>
                <div className="a-grid-item-row">
                    <div className="a-flexbox-item">
                        <svg className="a-page-break"><PageBreak/></svg>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About