import { useContext } from "react";
import { ThemeContext } from "../../context";
import "./project2.css";
import Prototype from "../../img/DATCH/DATCH_NEH_Demo.mp4";
import DATCHMeetingImg from "../../img/DATCH/DATCH_NEH_Meeting_img.jpg";
import LiveVideo from "../../img/DATCH/DATCH_Live_Demo.mp4";

// Carousel imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import CardImage from "./CardImage";
import CardVideo from "./CardVideo";

export const DATCH = () =>
{
    // Enables theme control
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1, 
        autoplay: true,
        autoplaySpeed: 3000, 
        slickPlay: true
    };

    return (
        <div className="ppg">
            <div className="ppg-grid-container">
                <div className="ppg-grid-item-row">
                    <h1 className="ppg-title">DATCH</h1>
                    <p className="ppg-paragraph">An AR drawing application that allows architects and archaeologists to document information on structures at excavation sites</p>
                </div>
            </div>

            <Slider {...settings}>
                <CardVideo source={LiveVideo} type="video/mp4"/>
                <CardImage source={DATCHMeetingImg} />
                <CardVideo source={Prototype} type="video/mp4" />
            </Slider>

            <div className="ppg-grid-container">
                <div className="ppg-grid-item-row">
                    <p className="ppg-paragraph" >
                        Documenting and Triaging Cultural Heritage (DATCH) is a GPS-enabled augmented reality application for the Microsoft Hololens 2. DATCH allows users to draw, record, and document rocks, artifacts, and structures in indoor and outdoor research locations. The project has been tested in the Kerkenes archaeological site in Turkey and is being developed by a small team and will later be expanded for use in other fields. An open source version of the application is available on GitHub.<br/><br/> My primary responsibilities: <i>XR development, initial prototype, UI/UX design, documentation</i>
                    </p>
                </div>
                <div className="ppg-grid-item-row">
                    <h3 className="ppg-subtitle" >Tools</h3>
                    <div className="ppg-grid-container-five-col">
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb14">
                                <div className="ppg-tools-text">Unity/C#</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb15">
                                <div className="ppg-tools-text">Windows MRTK</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb16">
                                <div className="ppg-tools-text">Adobe Photoshop</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb1">
                                <div className="ppg-tools-text">Adobe Illustrator</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb2">
                                <div className="ppg-tools-text">GitHub</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb3">
                                <div className="ppg-tools-text">Trello</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ppg-grid-item-row">
                    <h3 className="ppg-subtitle" >Links</h3>
                    <div className="ppg-grid-item-col">
                        <div className="ppg-grid-container-three-col">
                            <a target="_blank" className="pp-visit-site" href="https://sciences.ucf.edu/anthropology/datch/"><button className="ppg-button">Website</button></a>
                            <a target="_blank" className="pp-visit-site" href="https://github.com/datch-ucf/datch2-docs"><button className="ppg-button">Documentation</button></a>
                            <a target="_blank" className="pp-visit-site" href="https://github.com/datch-ucf/datch2"><button className="ppg-button">Open Source</button></a>
                        </div>
                    </div>
                </div>
                <div className="ppg-grid-item-row">
                        <a href="/#work-anchor" target="_self" className="pp-back-link">Go Back</a>
                </div>
            </div>
        </div>
    ) 
};