import "../Home/contact.css";
import { useContext, useRef, useState } from "react";
import emailjs from '@emailjs/browser';
import { ThemeContext } from "../../context";
import { ReactComponent as SketchyArrow } from "../../svg/sketchy-arrow3.svg";

const Contact = () =>
{
    const formRef = useRef();
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    // Creating a useState for after a form has been sent.
    // After successfully sending a message, we will set useState to true
    const [done, setDone] = useState(false)

    // e means event. This is the variable we are passing into the function (presumably)
    const handleSubmit = (e) =>
    {
        // Use this function to ensure that when you click the Submit button, it doesn't refresh the page.
        e.preventDefault()

        // Instead, we are going to do this when we submit the data
        // Copied and pasted from https://www.emailjs.com/docs/sdk/send-form/ after installing the emailjs package
        // Package installation instructions are here https://www.emailjs.com/docs/sdk/installation/
        // Be sure to paste service id, template id, etc. from EmailJS site
        // Can't find the user id referred to in the tutorial. Latest documentation says to use the public key instead (if you didn't use the init function).
        emailjs.sendForm('service_fvfoql8', 'template_ky6isap', formRef.current, 'FbEuVvdfpsK5aUtEb')
        .then((result) => {
            console.log(result.text);
            // After successfully sending a message, set useState to true
            setDone(true)
        }, (error) => {
            console.log(error.text);
        });

    }

    return (
        // c stands for contact
        <div className="c">
            <div className="c-grid-container-single-row">
                <div className="c-grid-item-col-left-align">
                    <div className="c-flexbox-item">
                        <a id="contact-anchor"><h1 className="c-title"> Contact </h1></a>
                    </div>
                    <div className="c-flexbox-item">
                        <h3 className="c-subtitle"> You can send me a message here! </h3>
                    </div>
                </div>
            </div>

            <div className="c-grid-container-single-row">
                <div className="c-grid-item-col">
                    <div className="c-flexbox-item">
                        <form ref={formRef} onSubmit={handleSubmit}>
                            <div className="c-grid-container contact-grid-container">
                                <div className="c-grid-item-col-left-align">
                                    {/* If we are in darkMode, change the color to #333 */}
                                    <div className="c-flexbox-item">
                                        <input className="c-name-input" style={{ backgroundColor: darkMode && "#333" }} type="text" placeholder="Name" name="user_name" />
                                    </div>
                                    <div className="c-flexbox-item">
                                        <input className="c-subject-input" style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Subject" name="user_subject"/>
                                    </div>
                                    <div className="c-flexbox-item">
                                        <input className="c-email-input" style={{backgroundColor: darkMode && "#333"}} type="text" placeholder="Email" name="user_email"/>
                                    </div>
                                </div>
                                <div className="c-grid-item-col">
                                    <div className="c-flexbox-item message-flexbox">
                                        <textarea className="c-message-textarea" style={{backgroundColor: darkMode && "#333"}} rows="5" placeholder="Message" name="message"/>
                                    </div>
                                    <div className="c-flexbox-item">
                                        <button>Submit</button>
                                        {/* if done is true, write a thank you message. You can also use if statements if preferred. */}
                                        {done && "Thank you!"}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            {/* Bottom Note */}
            <div className="c-grid-container-three-cols">
                <div className="c-grid-item-row"></div>
                <div className="c-grid-item-row c-bottom-note-cell">
                    <div className="c-flexbox-item c-bottom-note-flexbox">
                        <div className="c-bottom-note-text">
                            You can also contact me at the links below!
                        </div>
                    </div>
                </div>
                <div className="c-grid-item-row">
                    <div className="c-flexbox-item c-sketchy-arrow-flexbox">
                        <svg className="c-sketchy-arrow"><SketchyArrow /></svg>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Contact