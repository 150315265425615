import { useContext } from "react";
import { ThemeContext } from "../../context";
import "./project2.css";
import SpatialSafariDemoVid from "../../img/Spatial-Safari/SpatialSafari.mp4";

// Carousel imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import CardImage from "./CardImage";
import CardVideo from "./CardVideo";

export const SpatialSafari = () =>
{
    // Enables theme control
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1, 
        autoplay: true,
        autoplaySpeed: 3000, 
        slickPlay: true
    };

    return (
        <div className="ppg">
            <div className="ppg-grid-container">
                <div className="ppg-grid-item-row">
                    <h1 className="ppg-title">Spatial Safari</h1>
                    <p className="ppg-paragraph"> A photo adventure game where players must find the animals hiding around the environment</p>
                </div>
            </div>

            <Slider {...settings}>
                <CardVideo source={SpatialSafariDemoVid} type="video/mp4"/>
            </Slider>

            <div className="ppg-grid-container">
                <div className="ppg-grid-item-row">
                    <p className="ppg-paragraph" >
                        Spatial Safari is an interactive space created using Unity and the Spatial Creator Toolkit. In this experience, users must find one of several animals listed on in their quest list within the time limit. Armed with a camera, users must make their way to each animal hiding in the space, then take a photo. My primary responsibilities: <i>Visual scripting, environment design (assets obtained from Sketchfab)</i> 
                    </p>
                </div>
                <div className="ppg-grid-item-row">
                    <h3 className="ppg-subtitle" >Tools</h3>
                    <div className="ppg-grid-container-four-col">
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb14">
                                <div className="ppg-tools-text">Unity</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb1">
                                <div className="ppg-tools-text">Visual Scripting</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb15">
                                <div className="ppg-tools-text">Spatial Creator Toolkit</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb2">
                                <div className="ppg-tools-text">GitHub</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ppg-grid-item-row">
                        <a href="/#work-anchor" target="_self" className="pp-back-link">Go Back</a>
                </div>
            </div>
        </div>
    ) 

}