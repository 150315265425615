import "./project2.css";
import VRPlaySpaceConceptImg from "../../img/Virtual Reality Playspace/AestheticDesign.png";
import Background from "../../img/Virtual Reality Playspace/VR Playspace Screenshot.png";
import ColorTheory from "../../img/Virtual Reality Playspace/color-wheel-harmonies-.jpg";
import Table from "../../img/Virtual Reality Playspace/Table.png";
import Backpack from "../../img/Virtual Reality Playspace/Backpack.png";
import Staff from "../../img/Virtual Reality Playspace/Staff.png";
import Radio from "../../img/Virtual Reality Playspace/Radio.png";
import { useContext } from "react";
import { ThemeContext } from "../../context";
import FinalProjectDemo from "../../img/Virtual Reality Playspace/VRPlayspaceFinal.mp4";

// Carousel imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import CardImage from "./CardImage";
import CardVideo from "./CardVideo";

export const VRPlayspace = () =>
{
    // Enables theme control
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1, 
        autoplay: true,
        autoplaySpeed: 3000, 
        slickPlay: true
    };

    return (
        <div className="ppg">
            <div className="ppg-grid-container">
                <div className="ppg-grid-item-row">
                    <h1 className="ppg-title">Virtual Reality Playspace</h1>
                    <p className="ppg-paragraph">A VR environment that includes tools for exploration</p>
                </div>
            </div>

            <Slider {...settings}>
                <CardVideo source={FinalProjectDemo} type="video/mp4"/>
                <CardImage source={VRPlaySpaceConceptImg} />
                <CardImage source={Background} />
            </Slider>

            <div className="ppg-grid-container">
                <div className="ppg-grid-item-row">
                    <p className="ppg-paragraph" >
                    The Virtual Reality Playspace is an experimental virtual reality project for the Oculus Quest. Created for a graduate-level Advanced Virtual Reality course, the environment is a stylized floating island scene with interactable objects, animations, and atmospheric audio effects intended to encourage users to explore and manipulate the objects. <br/><br/> My primary responsibilities: <i>XR development, environment design, concept design</i>
                    </p>
                </div>
                <div className="ppg-grid-item-row">
                    <h3 className="ppg-subtitle" >Tools</h3>
                    <div className="ppg-grid-container-four-col">
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb14">
                                <div className="ppg-tools-text">Unity/C#</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb15">
                                <div className="ppg-tools-text">XR Interaction Toolkit</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb16">
                                <div className="ppg-tools-text">Blender 3D</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb1">
                                <div className="ppg-tools-text">GitHub</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ppg-grid-item-row">
                    {/* <h3 className="ppg-subtitle" >Links</h3>
                    <div className="ppg-grid-item-col">
                        <a target="_blank" className="pp-visit-site" href="https://sciences.ucf.edu/anthropology/datch/"><button className="ppg-button">Visit Website</button></a>
                        <a target="_blank" className="pp-visit-site" href="https://github.com/datch-ucf/datch2-docs"><button className="ppg-button">Visit GitHub</button></a>
                    </div> */}
                </div>
                <div className="ppg-grid-item-row">
                        <a href="/#work-anchor" target="_self" className="pp-back-link">Go Back</a>
                </div>
            </div>
        </div>
    ) 
};