import { Link } from "react-router-dom";
import "./navbar.css";
import { ReactComponent as HomeLogo } from './svg/HomeLogo2.svg';
import { ReactComponent as DarkModeLogo } from './svg/Night.svg';
import { ReactComponent as LightModeLogo } from './svg/Day.svg';
import { useContext } from "react";
import { ThemeContext } from "./context.js";

export const Navbar = () =>
{
    // This chunk helps make the button toggle the theme
    const theme = useContext(ThemeContext)
    
    var lightMode = new Boolean(true);
    
    const handleClick = () =>
    {
        // Toggle theme switch
        theme.dispatch({ type: "TOGGLE" }) 

        // Toggle icon switch
        // Check icon to see what mode we are currently in
        var darkIcon = document.getElementById("dark-img");

        // If dark-img is visible, remove it and replace with light-img
        if (window.getComputedStyle(darkIcon).display === "inline") {
            document.getElementById("dark-img").style.display = 'none';
            document.getElementById("light-img").style.display = 'inline';
        }
        
        // If light-img is visible, remove it and replace with dark-img
        else if(window.getComputedStyle(darkIcon).display === "none")
        {
            document.getElementById("light-img").style.display = 'none';
            document.getElementById("dark-img").style.display = 'inline';
        }
    }
    return (
        // n stands for navbar
        <div className="n"> 
            <div className="n-grid-container">
                <div className="n-grid-item-row">
                    <div className="n-flexbox-item">
                        <Link to="/"><svg className="n-logo"><HomeLogo /></svg></Link>
                    </div>
                    <div className="n-flexbox-item n-right-links-flexbox">
                        <div className="n-right-links">
                            {/* Added anchor points */}
                            <a href="/#work-anchor" target="_self" className="work-link"> Work </a>
                            <a href="/#about-anchor" target="_self" className="about-link"> About </a>
                            <a href="/#contact-anchor" target="_self" className="contact-link"> Contact </a>
                            
                            {/* Functional, but removing for now */}
                            {/* <svg id="dark-img" className="mode-switch dark" onClick={handleClick}><DarkModeLogo /></svg>
                            <svg id="light-img" className="mode-switch light" onClick={handleClick}><LightModeLogo /></svg> */}

                        </div>
                    </div>
                </div>
            </div>    
        </div>
    )
}

// export default Navbar