import "./project.css";
import LobbySitemap from "../../img/Partnership 2 Lobby Laser Scans/p2lobby_sitemap.png";
import ScannerIcon from "../../img/Partnership 2 Lobby Laser Scans/scanner-icon.png";
import InfraredScan1 from "../../img/Partnership 2 Lobby Laser Scans/p2lobbyint__2.png";
import ResultScan1 from "../../img/Partnership 2 Lobby Laser Scans/p2lobby1.png";
import FinalResult from "../../img/Partnership 2 Lobby Laser Scans/p2lobby_2.png";
import { useContext } from "react";
import { ThemeContext } from "../../context";

// Carousel imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import CardImage from "./CardImage";
import CardVideo from "./CardVideo";

export const LaserScanning = () =>
{
    // Enables theme control
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1, 
        autoplay: true,
        autoplaySpeed: 3000, 
        slickPlay: true
    };

    return (
        <div className="ppg">
            <div className="ppg-grid-container">
                <div className="ppg-grid-item-row">
                    <h1 className="ppg-title">Laser Scanning</h1>
                    <p className="ppg-paragraph">A digital recreation of a building lobby constructed using laser scanning tools</p>
                </div>
            </div>

            <Slider {...settings}>
                <CardImage source={FinalResult} />
                <CardImage source={LobbySitemap} />
                <CardImage source={InfraredScan1} />
                <CardImage source={ResultScan1} />
                
            </Slider>

            <div className="ppg-grid-container">
                <div className="ppg-grid-item-row">
                    <p className="ppg-paragraph" >
                    This laser scanning project is a portion of a large-scale research project for ChronoPoints, a University of Central Florida (UCF) research project focused on creating digital scans of various university locations. I assisted with scanning the lobby of one of the university's buildings. <br/><br/> My primary responsibilities: <i>Assist with operating scanner, aligning scans, and repositioning the scanner to obtain information missing between datapoints</i>
                    </p>
                </div>
                <div className="ppg-grid-item-row">
                    <h3 className="ppg-subtitle" >Tools</h3>
                    <div className="ppg-grid-container-two-col">
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb14">
                                <div className="ppg-tools-text">Leica RTC360</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb15">
                                <div className="ppg-tools-text">iPad</div>
                            </div>
                        </div>
                        {/* <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb16">
                                <div className="ppg-tools-text">Scanning Software</div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="ppg-grid-item-row">
                    <h3 className="ppg-subtitle" >Links</h3>
                    <div className="ppg-grid-item-col">
                        <a target="_blank" className="pp-visit-site" href="https://chronopoints.eecs.ucf.edu/category/laser-scanning/"><button className="ppg-button">Visit Website</button></a>
                    </div>
                </div>
                <div className="ppg-grid-item-row">
                        <a href="/#work-anchor" target="_self" className="pp-back-link">Go Back</a>
                </div>
            </div>
        </div>
    ) 
};