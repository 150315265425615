import "./footer.css";
import { ReactComponent as EmailLogo } from './svg/email.svg';
import { ReactComponent as GitHubLogo } from './svg/icons8-github-30.svg';
import { ReactComponent as LinkedInLogo } from './svg/linkedin.svg';

let copyright = String.fromCodePoint(0x00A9);

export const Footer = () =>
{
    return (
        // f stands for footer
        <div className="f">  
            <div className="f-grid-container-single-row">
                <div className="f-grid-item-row">
                    <div className="flexbox-item">
                        <div className="copyright-symbol">{copyright}</div><div className="f-copyright-note">{" Jeralyn Parchman " + new Date().getFullYear() + " All Rights Reserved"}</div>
                    </div>
                    
                    <div className="flexbox-item f-logo-links">
                        <a href="/#contact-anchor" target='_self' rel='noopener noreferrer' className="f-logo"><svg className="f-email-logo"><EmailLogo /></svg></a>
                        <a href="https://www.linkedin.com/in/jeralyn-parchman" target='_blank' rel='noopener noreferrer' className="f-logo"><svg className="f-linkedin-logo"><LinkedInLogo /></svg></a>
                        <a href="https://github.com/JeralynP" target='_blank' rel='noopener noreferrer' className="f-logo"><svg className="f-github-logo"><GitHubLogo /></svg></a>
                    </div>
                </div>
            </div>
        </div>
    )
}