import "./project.css";
import RhinoMainImg from "../../img/Digital Twin/P2 Office - Rhino.png";
import MeasurementsDrawing from "../../img/Digital Twin/DigitalDrawingofMeasurements.png";
import MayaImg from "../../img/Digital Twin/DigitalTwinMaya.png";
import RhinoScriptImg from "../../img/Digital Twin/RhinoScriptScreenshot.png";
import { useContext } from "react";
import { ThemeContext } from "../../context";

// Carousel imports
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import Slider from "react-slick";
import CardImage from "./CardImage";
import CardVideo from "./CardVideo";

export const DigitalTwin = () =>
{
    // Enables theme control
    const theme = useContext(ThemeContext);
    const darkMode = theme.state.darkMode;

    var settings = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 800,
        slidesToShow: 1,
        slidesToScroll: 1, 
        autoplay: true,
        autoplaySpeed: 3000, 
        slickPlay: true
    };

    return (
        <div className="ppg">
            <div className="ppg-grid-container">
                <div className="ppg-grid-item-row">
                    <h1 className="ppg-title">Digital Twin</h1>
                    <p className="ppg-paragraph">A digital office space model created with Rhino, Python, and Autodesk Maya</p>
                </div>
            </div>

            <Slider {...settings}>
                <CardImage source={RhinoMainImg} />
                <CardImage source={MeasurementsDrawing} />
                <CardImage source={RhinoScriptImg} />
                <CardImage source={MayaImg} />
            </Slider>

            <div className="ppg-grid-container">
                <div className="ppg-grid-item-row">
                    <p className="ppg-paragraph" >
                    This digital twin of an office space is a high-precision virtual recreation of a real-world environment. The measurements of the room and furniture were obtained manually using rulers and other measuring tools, while the space was modeled using an algorithm and 3D modeling software. Details were added to the basic scene to create the final render of the space. <br/><br/> My primary responsibilities: <i>3D modeling, measurements</i>
                    </p>
                </div>
                <div className="ppg-grid-item-row">
                    <h3 className="ppg-subtitle" >Tools</h3>
                    <div className="ppg-grid-container-three-col">
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb14">
                                <div className="ppg-tools-text">Rhinoceros 3D</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb15">
                                <div className="ppg-tools-text">Python</div>
                            </div>
                        </div>
                        <div className="ppg-grid-item-col">
                            <div className="ppg-sketchy-box-text-flexbox sb16">
                                <div className="ppg-tools-text">AutoDesk Maya</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="ppg-grid-item-row">
                    <h3 className="ppg-subtitle" >Links</h3>
                    <div className="ppg-grid-item-col">
                        <a target="_blank" className="pp-visit-site" href="https://sciences.ucf.edu/anthropology/datch/"><button className="ppg-button">Visit Website</button></a>
                        <a target="_blank" className="pp-visit-site" href="https://github.com/datch-ucf/datch2-docs"><button className="ppg-button">Visit GitHub</button></a>
                    </div>
                </div> */}
                <div className="ppg-grid-item-row">
                        <a href="/#work-anchor" target="_self" className="pp-back-link">Go Back</a>
                </div>
            </div>
        </div>
    ) 
};